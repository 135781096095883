import React from "react";
import { Link } from "gatsby";
import Layout from "../../../components/layout.pt";
import SEO from "../../../components/seo";

const JamStackPT = () => (
    <Layout>
        <SEO title="JamStack Developers" lang="pt" />

        <div className="bk-about-area section-ptb-100 mt-5" id="section-about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="thumb text-left text-lg-left wow move-up">
                            <img src="/images/cherry-online-team-meeting-1.png" alt="jamstack" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                        <div className="content pl--80 pl_md--5 pl_sm--5">
                        <div className="title--creative wow move-up">
                                        <h2>
                                            Rápido e seguro
                                            <br />
                                            <span className="theme-creative">JAMstack</span> developers
                                        </h2>
                                    </div>
                            <p className="wow move-up">JAMstack, arquitetura de desenvolvimento web baseada em JavaScript, APIs e Markup, é a resposta perfeita para os problemas modernos de desenvolvimento web.</p>
                                    <Link to="/pt/contacte-nos" className="about-btn wow move-up">
                                        <span>Trabalhe connosco </span>
                                        <i class="fa fa-arrow-right"></i>
                                    </Link>
                        </div>
                    </div>
                
                    <div class="col-lg-2 order-2 order-lg-1 mt_md--40 mt_sm--40">
                        <div class="blog-post-return-button">
                            <a href="/"><i class="fa fa-arrow-left"></i> Voltar</a>
                        </div>
                    </div>

                    <div className="col-lg-8 order-1 order-lg-2">
                            <div className="blog-details-wrapper">
                                <article className="blog-post blog-modern-layout">
                                  
                                    <div className="content basic-dark2-line-1px pb--50 mb--35">
                                        <div className="inner mb--50">
                                            <h5 className="heading heading-h5 line-height-1-95 wow move-up mb--50">Nos últimos anos, o cenário de desenvolvimento web tem evoluído gradualmente. Neste momento, a maioria dos <i>developers</i> está ciente de certas desvantagens quando se trata de usar um CMS tradicional, como Wordpress e/ou Drupal.<br/>Com o Google a melhorar os seus recursos de <i>tracking</i> e renderização de Javascript e favorecendo a velocidade como um dos fatores mais importantes, fica notório o porquê de sites estáticos e pré-renderizados surgirem como a solução perfeita para os actuais desafios.</h5>
                                        </div>
                                        <div className="thumbnail wow move-up">
                                            <img className="w-100" src="/images/uillian-vargas-7oJpVR1inGk-unsplash.jpg" alt="Multipurpose" />
                                            <span>Photo by <a href="https://unsplash.com/@vargasuillian?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Uillian Vargas</a> on <a href="https://unsplash.com/s/photos/fast?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
                                        </div>
                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Gostariamos hoje de explicar a ideia principal por trás do JAMstack, em como difere das soluções tradicionais e porque é que é melhor para a <i>web</i>, <i>developers</i> e clientes.</p>
                                        </div>


                                        <div className="bk-quote-content">
                                            <blockquote className="brook-quote move-up wow">
                                                <div className="quote-text">O que é JAMstack?</div>
                                            </blockquote>
                                        </div>

                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Para colocar da forma mais simples possível, JAMstack é uma abreviatura criada por Mathias Biilmann, o CEO da Netlify e significa JavaScript, APIs e Markup. Representa uma arquitetura de desenvolvimento web moderna, que dá aos <i>developers</i> a oportunidade de contar com as vantagens de um site estático, que incluem melhor desempenho da web e benefícios de segurança, ao mesmo tempo que mantém os atributos dinâmicos de um CMS orientado a base de dados mas sem a base de dados.</p>
                                            <p className="bk_pra mt--20">É imperativo apontar que com JAMstack as regras e benefícios são bastante autoexplicativas e diretas:</p>
                                            <ul className="mt--20">
                                                <li>JavaScript (no lado do cliente) é responsável pelo ciclo de pedido/resposta.</li>
                                                <li>APIs reutilizáveis têm acesso, via HTTPS e JavaScript, a todos os processos do lado do servidor ou ações na base de dados.</li>
                                                <li>O Markup deve ser pré-construído desde raíz, utilizando um gerador de site ou uma ferramenta de construção para apps.</li>
                                            </ul>

<p className="bk_pra mt--20 ">Para apresentar este tema em contexto, vamos comparar a tecnologia dominante no <i>web development</i>, a solução LAMP, com a tecnologia emergente, JAMstack.</p>
<p className="bk_pra mt--20">A arquitetura de <i>web development</i> da solução LAMP, originou-se de quatro componentes de código aberto que os <i>developers</i>, no início dos anos 2000, adotaram para construir sites: o sistema operativo Linux, o servidor HTTP Apache, a base de dados MySQL e a linguagem de programação PHP.</p>
<p className="bk_pra mt--20">A maneira como as coisas funcionam para sites construídos com LAMP é que, com cada solicitação de uma página do utilizador, um servidor primeiro consulta uma base de dados e combina o resultado com os dados da Markup da página e plug-ins para gerar um documento HTML no navegador.</p>
<p className="bk_pra mt--20">Os sites JAMstack, por outro lado, fornecem arquivos estáticos imediatamente quando uma solicitação é feita. Isto pode ser feito porque não há necessidade de consultar a base de dados, pois os arquivos já estão compilados e fornecidos ao navegador a partir de um CDN.</p>
<p className="bk_pra mt--20">Resumindo, o fluxo de trabalho do JAMstack reduz drasticamente os obstáculos de desenvolvimento e o excesso de manutenção.</p>
                                        </div>


                                        <div className="bk-quote-content">
                                            <blockquote className="brook-quote move-up wow">
                                                <div className="quote-text">Porque é que o JAMstack é importante agora?</div>
                                            </blockquote>
                                        </div>
                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Com o aumento do uso de dispositivos móveis, as empresas online enfrentam certos desafios. Para começar, a atenção média do utilizador está a reduzir rapidamente. Hoje, a maioria das pessoas deseja obter suas informações ou aceder a um site / vídeo imediatamente. É por isso que a maioria das empresas online está empenhada em fornecer acesso mais rápido ao conteúdo. Neste sentido, marcas globais estão a invadir o mercado com as suas próprias soluções.</p>
                                            <p className="bk_pra mt--20">O Google lançou o AMP Project, uma iniciativa de código aberto que visa melhorar o ecossistema de conteúdo para todos. Resumindo, as páginas AMP são desenvolvidas com três componentes principais: AMP HTML, AMP JS e AMP Cache. O seu objetivo é tornar as páginas mais rápidas em dispositivos e plataformas de distribuição.</p>
                                            <p className="bk_pra mt--20">O Facebook tem Instant Articles que visa resolver alguns problemas específicos que os utilizadores do Facebook enfrentam, especialmente o tempo de carregamento na internet móvel.</p>
                                            <p className="bk_pra mt--20">Até a Apple lançou o seu formato de notícias como uma solução para a velocidade de entrega de conteúdo multimédia dinâmico.</p>
                                            <p className="bk_pra mt--20">As tendências da indústria são direcionadas para a tecnologia móvel poderosa e o aparecimento de navegadores web modernos e rápidos, o que está a levar os developers a procurar soluções práticas. É aí que entra o JAMstack! </p>
                                            <p className="bk_pra mt--20">Há mais razões que alimentaram a necessidade do JAMstack. Para começar, a economia de API atual, SSG (Static Site Generators), número de headless CMSs e CDNs (Content Delivery Networks) avançados para melhor distribuição de conteúdo.</p>
                                        </div>


                                        <div className="bk-quote-content">
                                            <blockquote className="brook-quote move-up wow">
                                                <div className="quote-text">Como é que o JAMstack responde aos problemas modernos de web development?</div>
                                            </blockquote>
                                        </div>
                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Para entender como o JAMstack opera na prática, temos que distinguir os seguintes sites:</p>
                                            <ul>
                                                <li><strong>Sites pré-renderizados</strong> (também conhecidos como sites estáticos): Basicamente, um site pré-renderizado é um site composto por páginas HTML, com conteúdo pré-preenchido. O navegador simplesmente solicita um URL de uma página estática, após a qual o servidor retorna a página estática completa. A parte boa deste sistema é que nenhuma chamada é feita para uma base de dados e não há conteúdo gerado instantaneamente. Por outras palavras, o conteúdo solicitado pelo utilizador já está lá. No entanto, isto não significa que todas as páginas estão a ser colocadas manualmente e não significa que não pode haver elementos dinâmicos nessas páginas. Por esse motivo, várias ferramentas diferentes lidam com diversas camadas dessa tecnologia.</li>
                                                <li><strong>Sites dinâmicos:</strong> o desempenho da web é uma parte essencial do <i>web development</i> moderno e não importa o quão bem um site seja otimizado, os sites dinâmicos acabam por ser extremamente lentos, porque há muitas etapas que precisam de ser executadas antes do conteúdo real ser carregado, sempre que um user acede o site/página. Sites dinâmicos podem ser tão rápidos quanto os estáticos, mas é muito mais difícil de conseguir e com um custo muito maior. Além disso, com tantas peças móveis, ficará muito mais vulnerável a ataques.</li>
                                            </ul>
                                            <p className="bk_pra mt--20">Um problema adicional é que muitos sites estão a ser construídos através de soluções CMS tradicionais (ou seja, WordPress ou semelhantes). Embora estes não sejam maus em si, com o tempo ficam sobrecarregados com uma grande quantidade de scripts, plug-ins e uma variedade de elementos adicionais, que na verdade parecem redundantes. Por causa disso, os tempos de carregamento são reduzidos drasticamente para cada site ou projeto de desenvolvimento web em desenvolvimento.</p>
                                        </div>


                                        <div className="bk-quote-content">
                                            <blockquote className="brook-quote move-up wow">
                                                <div className="quote-text">O que ganha o cliente?</div>
                                            </blockquote>
                                        </div>
                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Ao discutir JAMstack com um público experiente em tecnologia tudo parece simples e compreensivel. Mas, na maioria das vezes, deparamo-nos com um cliente que precisa de um pouco de educação sobre JAMstack e os seus benefícios. A melhor maneira de fazer o cliente entender o JAMstack é explicar os benefícios que os seus negócios obteriam ao usá-lo.</p>
                                            
                                            <h5 className="bk_pra mt--20 "><strong>Performance</strong></h5>
                                            <p className="bk_pra mt--20">Como já mencionamos, a velocidade de carregamento da página está a tornar-se um dos fatores de classificação mais importantes. O que torna o JAMstack tão rápido é que ele remove a base de dados da arquitetura do sistema web, o que significa que um site não precisa de executar várias consultas atrasando progressivamente o seu desempenho.</p>
                                            <p className="bk_pra mt--20">Ou seja, pode argumentar que sites estáticos são sites mais rápidos e com melhores classificações o que, por sua vez, equivale a mais tráfego e mais retorno.
Para garantir que se veja a diferença nas pontuações de desempenho compare-se sites como o da Smashing Magazine que se tornou 10 vezes mais rápido quando mudou do WordPress para um site estático.</p>

<h5 className="bk_pra mt--20 "><strong>Segurança</strong></h5>
<p className="bk_pra mt--20">Com a arquitetura JAMstack, o front-end e o CMS são desacoplados e os processos do lado do servidor são executados por APIs. Isso por si só reduz o tempo inicial de exibição do website.</p>
<p className="bk_pra mt--20">Um site estático é um site mais seguro e confiável, o que equivale a menos despesas de segurança.</p>

<h5 className="bk_pra mt--20 "><strong>Fluxo de Trabalho</strong></h5>
<p className="bk_pra mt--20">A maioria de sites de CMS dinâmicos estão a tornar-se desordenados, o que provou ser um grande problema, especialmente nos últimos anos. Mais cedo ou mais tarde, isso também se irá tornar um problema para o clientes.</p>
<p className="bk_pra mt--20">Pode argumentar que abandonar a base de dados, os plug-ins e a manutenção do alojamento, irá fazer com que se economizem recursos e reduza os custos operacionais e de desenvolvimento.</p>

<h5 className="bk_pra mt--20 "><strong>Crescimento</strong></h5>
<p className="bk_pra mt--20">Finalmente, o conteúdo estático em sites JAMstack pode ser colocado num CDN, o que torna mais fácil escalar dinamicamente.</p>

                                            
                                          
                                        </div>

                                        <div className="bk-quote-content">
                                            <blockquote className="brook-quote move-up wow">
                                                <div className="quote-text">As limitações do JAMstack</div>
                                            </blockquote>
                                        </div>
                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Sim, o JAMstack é revolucionário em muitos aspetos. Sim, pode ajudá-lo a criar um site muito melhor ou a apresentar a sua empresa de maneira muito melhor do que a concorrência. No entanto, como qualquer outra tecnologia, também tem algumas limitações.</p>
                                            <p className="bk_pra mt--20">Por exemplo, se o seu projeto é baseado em atualizações frequentes, atualizações em tempo real e recursos dinâmicos com os quais os utilizadores podem interagir, embora o JAMstack tenha desenvolvido muito neste aspecto, pode não ser a melhor opção para si.</p>
                                            <p className="bk_pra mt--20">Galerias, comentários, formulários e outros recursos interativos só podem ser adicionados a um site JAMstack usando soluções externas. </p>
                                            <p className="bk_pra mt--20">Por fim, qualquer mudança no design deve passar pelo <i>developer</i>. Portanto, provavelmente terá de manter contacto com os seus <i>developers</i> JAMstack (o que não é necessariamente mau).</p>
                                        </div>

                                        <div className="bk-quote-content">
                                            <blockquote className="brook-quote move-up wow">
                                                <div className="quote-text">Introdução ao JAMstack</div>
                                            </blockquote>
                                        </div>
                                        <div className="desc mt--45">
                                            <p className="bk_pra wow move-up">Antes de começar, saiba isto: JAMstack é focado no desenvolvimento frontend. Se geralmente é novo no desenvolvimento web e gostaria de começar com o JAMstack, é melhor obter algum conhecimento básico em JavaScript e API em primeiro lugar.</p>
                                            <p className="bk_pra mt--20">Para criar um site JAMstack, usará a estrutura JavaScript à sua escolha e/ou um gerador de site estático para uma construção headless CMS para gerir o conteúdo e uma plataforma de alojamento.</p>
                                        </div>

                                        <div class="row pt--150 pt_md--50 pt_sm--50">
                                            <div class="col-lg-4 col-md-12 col-12">
                                                <div class="digital-marketing wow move-up">
                                                    <h3 class="heading heading-h3">Frameworks JavaScript</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-12 col-12 offset-lg-1">
                                                <div class="digital-marketing wow move-up mt_md--30 mt_sm--30">
                                                    <div class="inner">
                                                        <p class="bk_pra">Os últimos anos pertenceram a JS. Ele está presente em todo o lado e, ao longo dos anos, foi fundamental para muitas estruturas em rápida evolução que popularizaram apps de página única e PWAs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row pt--150 pt_md--50 pt_sm--50">
                                            <div class="col-lg-4 col-md-12 col-12">
                                                <div class="digital-marketing wow move-up">
                                                    <h3 class="heading heading-h3">Geradores de sites estáticos</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-12 col-12 offset-lg-1">
                                                <div class="digital-marketing wow move-up mt_md--30 mt_sm--30">
                                                    <div class="inner">
                                                        <p class="bk_pra">Um gerador de site estático (Static Site Generator ou SSG) é uma ferramenta que cria páginas HTML estáticas a partir dos arquivos de entrada. Através do seu conteúdo de CMS, ou de outro lugar, aplica um modelo gerando uma estrutura de arquivos HTML puramente estáticos, fornecendo páginas prontas para a web. Há um grande número de SSGs disponíveis, adequados para quase todas as linguagens de programação. 
<br/>
Todos temos favoritos e nossos são: <Link to="https://www.gatsbyjs.com/">Gatsby</Link>, <Link to="https://nextjs.org/">NextJs</Link> e <Link to="https://gohugo.io/">Hugo</Link>.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row pt--150 pt_md--50 pt_sm--50">
                                            <div class="col-lg-4 col-md-12 col-12">
                                                <div class="digital-marketing wow move-up">
                                                    <h3 class="heading heading-h3">Headless CMSs</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-12 col-12 offset-lg-1">
                                                <div class="digital-marketing wow move-up mt_md--30 mt_sm--30">
                                                    <div class="inner">
                                                        <p class="bk_pra">Provavelmente já ouviu falar dos CMSs tradicionais, como Wordpress ou Drupal, e como eles funcionam, ou seja, são hospedados e respondem sempre que uma solicitação de página é feita. Em oposição a isso, um CMS headless separa a gestão de conteúdo do frontend e do backend. Isso permite que possa entregar o seu conteúdo para além de sites ou apps e redirecione o seu conteúdo a partir de muitos frontends diferentes.
<br/>
A maioria dos headless CMSs disponíveis encaixam-se em qualquer tecnologia frontend que possa estar a usar.</p>
<p class="bk_pra  mt--45" >Conheça o nosso Headless CMS Favorito, o <Link to="https://whitebrim.co">Whitebrim</Link>, desenvolvido internamente na Cottonhat para colmatar o espaço dos Headless CMS em e-commerce.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row pt--150 pt_md--50 pt_sm--50">
                                            <div class="col-lg-4 col-md-12 col-12">
                                                <div class="digital-marketing wow move-up">
                                                    <h3 class="heading heading-h3">Alojamento</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-12 col-12 offset-lg-1">
                                                <div class="digital-marketing wow move-up mt_md--30 mt_sm--30">
                                                    <div class="inner">
                                                        <p class="bk_pra">Agora que construiu o seu site JAMstack ou uma app com uma das ferramentas mencionadas acima, vamos-lhe encontrar um lar. Hoje em dia, as soluções de alojamento e CDNs modernos cresceram, oferecendo praticamente tudo o que precisa para enviar um site moderno.
<br/>
Basta olhar para as nossas soluções favoritas, como o <Link to="https://netlify.com">Netlify</Link> e o <Link to="https://vercel.com">Vercel</Link>. Ou os mais tradicionais, como o Firebase e o AWS.
<br/>
Tudo isto só mostra o que há de melhor no JAMstack. O facto de que pode manipular as ferramentas de alojamento, construção e gestão de conteúdo da maneira que desejar, criando a melhor solução para o seu projeto atual.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                   
                                </article>
                            </div>
                        </div>

                    </div>

                
            </div>
        </div>
    </Layout>
);

export default JamStackPT;
